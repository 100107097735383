
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import ElInputVeeValidate from "@/components/forms/ElInputVeeValidate.vue";
import { useI18n } from "vue-i18n";
import ErrorResponseModal from "@/components/modals/signup-page/ErrorResponseModal.vue";
import SuccessResponseModal from "@/components/modals/signup-page/SuccessResponseModal.vue";

import { validateNumberPhone, onlyTextAccept } from "@/core/helpers/helps";
import PrivacyPolicy from "@/components/pages/privacy-poilicy/Privacy-Poilicy.vue"
export default defineComponent({
  name: "sign-up",
  components: {
    Form,
    ElInputVeeValidate,
    ErrorResponseModal,
    SuccessResponseModal,
    PrivacyPolicy
  },
  head() {
    return {
      title: 'sign-up',
      meta: [
        {
          name: 'robots',
          content: 'noindex',
        },
      ],
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const showConfir = ref(true);
    const submitButton = ref<HTMLElement | null>(null);
    const showPassword = ref(true);
    const phone = ref("");
    const isLoading = ref(false);
    const value = ref('')
    const bumnSelected = ref('')
    const email = ref<any>('');
    const channel = ref<any>([]);
    const workspaces = ref<any>([]);
    const tenant = ref<any>([]);

    const nameField = ref<any>({value:null});
    const usernameField = ref<any>({value:null});
    const emailField = ref<any>({value:null});
    const phoneField = ref<any>({value:null});
    const nipField = ref<any>({value:null});
    const activeName = ref("first");
    const getChannel = computed(() => {
      return store.getters.getListChannel;
    });
    const privacyPolicyModal = ref<boolean>(false);
    const privacyPolicyStatus = ref<boolean>(false);
    const errorCheck = () => {
      console.log('Error');
    }

    onMounted(() => {
      setCurrentPageTitle(t("signUpPage.signUpText"));
      store.dispatch(Actions.GET_CHANNEL_LIST)
      if(route.query.token){
      store.dispatch(Actions.GET_USER_BY_TOKEN, route.query.token).then((response) => {
        nameField.value.value = response.name
        usernameField.value.value = response.username
        emailField.value.value = response.email
        phoneField.value.value = response.phone
        phone.value = response.phone
        nipField.value.value = response.nip
        bumnSelected.value = response.tenants[0].workspaces[0].channels[0]
      })}
    });

    const changeTab = (tabName) => {
      activeName.value = tabName;
    };

    const errors = computed(() => {
      return store.getters.getErrors;
    });

    const successModal = ref(false);
    const failedModal = ref(false);
    const failedMessage = ref(false);

    const signUpSchema = Yup.object().shape({
      name: Yup.string().required().label(t("signUpPage.formLabelName")),
      username: Yup.string()
        .required()
        .matches(/^[a-z0-9]*$/, t("signUpPage.errorValidateUsername")),
      nip: Yup.string().matches(/^[0-9]+$/, t('signUpPage.warningNik')),
      email: Yup.string()
        .min(4)
        .required()
        .email()
        .label(t("signUpPage.formLabelEmail")),
      password: Yup.string()
        .required()
        .min(8)
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/, t('signUpPage.errorValidatePassword')),
      phone: Yup.string()
        .min(10)
        .required()
        .label('Phone'),
      confirm_password: Yup.string()
        .min(7)
        .required()
        .oneOf(
          [Yup.ref("password"), null],
          t("signUpPage.formErrorConfirmPassword")
        )
        .label(t("signUpPage.formLabelConfirmPassword")),
    });
    
    const warning = ref('')
    const onSubmitRegister = (values, res) => {
      if (activeName.value == "first") {
        if (!privacyPolicyStatus.value) {
          privacyPolicyModal.value = true;
          return
        }
        changeTab("second");
        
      } else {
        if (!privacyPolicyStatus.value) {
          privacyPolicyModal.value = true;
          return
        }
        isLoading.value = true;
        submitButton.value?.setAttribute("data-kt-indicator", "on");

        const payload = {
          name: values.name,
          username: values.username,
          email: values.email,
          password: values.password,
          phone: phone.value,
          tenants: tenant.value,
          nip: values.nip,
          token: route.query.token || undefined,
        };

        store
          .dispatch(Actions.REGISTER, payload)
          .then(() => {
            successModal.value = true;
            email.value = payload.email;

            isLoading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");
          })
          .catch((error) => {
            warning.value = error
            failedModal.value = true;
            failedMessage.value = errors.value;
            isLoading.value = false;
            submitButton.value?.removeAttribute("data-kt-indicator");
          })
          
      }
    };

    watch(
      () => phone.value,
      (after, before) => {
        if (after.match(/^-\d*\.?\d+$/)) {
          phone.value = after.split("-")[1];
        }
      }
    );

    const findIndex = (masterArray, id) => {
      return masterArray.findIndex((elFind) => elFind.id === id);
    }

    watch(
      () => bumnSelected.value,
      (after, before) => {
        if(store.getters.getListChannel.length < 1) return
        var tmpTenant: any = [];
        tenant.value = [];
        let dataTmp = [after]
        dataTmp.map((channel_id, indexChannel) => {
          const channelDetail = store.getters.getListChannel.filter((el) => el._id === channel_id);
          
          if (findIndex(tenant.value, channelDetail[0].tenant_id) === -1) {
            tenant.value.push({
              id : channelDetail[0].tenant_id,
              workspaces : []
            })

            if (findIndex(tenant.value, channelDetail[0].tenant_id) > -1) {
              let indexWorkspace = findIndex(tenant.value[findIndex(tenant.value, channelDetail[0].tenant_id)].workspaces, channelDetail[0].workspace_id)
              if (indexWorkspace === -1) {
                tenant.value[findIndex(tenant.value, channelDetail[0].tenant_id)].workspaces.push({
                  id : channelDetail[0].workspace_id,
                  channels : []
                })
              }

              if (findIndex(tenant.value[findIndex(tenant.value, channelDetail[0].tenant_id)].workspaces, channelDetail[0].workspace_id) > -1) {
                  let indexChannel = findIndex(tenant.value[findIndex(tenant.value, channelDetail[0].tenant_id)].workspaces[findIndex(tenant.value[findIndex(tenant.value, channelDetail[0].tenant_id)].workspaces, channelDetail[0].workspace_id)].channels, channelDetail[0]._id)

                  if (indexChannel === -1) {
                    tenant.value[findIndex(tenant.value, channelDetail[0].tenant_id)].workspaces[findIndex(tenant.value[findIndex(tenant.value, channelDetail[0].tenant_id)].workspaces, channelDetail[0].workspace_id)].channels.push(channelDetail[0]._id)
                  }                
              }
            }
        }

        })

      }
    );

    const showPasswordText = (type) => {
      if (type == "confirm_password") {
        showConfir.value = !showConfir.value;
      } else {
        showPassword.value = !showPassword.value;
      }
    };
  

    const cancelRegister = () => router.push("/sign-in");
    const register = () => router.push("/sign-in");
    let industries = [
      "Agriculture",
      "Banking & Finance",
      "Marketing",
      "Engineering",
      "Information & Technology",
      "Law",
      "Government",
      "Design",
      "Architecture",
      "Education",
      "Manufacturing",
      "Automotive",
      "Property",
      "Tourism",
    ];
    function handleClose(type: string): void {
      type === "success"
        ? (successModal.value = false)
        : (failedModal.value = false);

        type === "success" && router.push({ path: "/sign-in" });

        setTimeout(() => {
          location.reload()
        }, 500)
    }

    function isNumber (evt: KeyboardEvent): void {
        const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        const keyPressed: string = evt.key;
        
        if (!keysAllowed.includes(keyPressed)) {
              evt.preventDefault()
        }
    }
    const checkPrivacyPolicy = (value) => {
      privacyPolicyStatus.value = value;
    };
    const closePrivacyPolicy = () => {
      privacyPolicyModal.value = false;
    };
    return {
      phone,
      validateNumberPhone,
      showPassword,
      showConfir,
      handleClose,
      email,
      successModal,
      failedModal,
      industries,
      cancelRegister,
      register,
      bumnSelected,
      activeName,
      changeTab,
      signUpSchema,
      onSubmitRegister,
      submitButton,
      errors,
      showPasswordText,
      getChannel,
      value,
      isNumber,
      failedMessage,
      onlyTextAccept,
      isLoading,
      nameField,
      usernameField,
      emailField,
      phoneField,
      nipField,
      errorCheck,
      warning,
      privacyPolicyModal,
      privacyPolicyStatus,
      checkPrivacyPolicy,
      closePrivacyPolicy
    };
  },
});
