import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-group-lg d-flex w-100 position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    error: $setup.errorMessage,
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_input, _mergeProps({
          autocomplete: "off",
          maxlength: $props.name == 'phone' ? 20 : 100,
          placeholder: $props.label,
          "show-password": ['password','new-password', 'katasandilama', 'katasandibaru', 'cekkatasandibaru', 'confirm_password'].includes($props.name),
          modelValue: $setup.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event))
        }, _ctx.$attrs), null, 16, ["maxlength", "placeholder", "show-password", "modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["error"]))
}