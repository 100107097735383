

import { ref } from 'vue'
export default {
    name: "modal-privacy-policy",
    props: {
        open: Boolean,
        status: Boolean,
    },
    setup(props: any, { emit }) {
        const dialogPrivacyPolicy = ref(props.open)
        const statusPrivacyPolicy = ref(props.status)
        function setPrivacyPolicy() {
            emit('checkPrivacyPolicy', statusPrivacyPolicy.value)
        }
        function closePrivacyPolicy() {
            // dialogPrivacyPolicy.value = false;
            emit('close')
        }
        return {
            setPrivacyPolicy,
            dialogPrivacyPolicy,
            statusPrivacyPolicy,
            closePrivacyPolicy
        }
    }
}
