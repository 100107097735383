
import { useField } from "vee-validate";
import { ref, watch } from "vue";

export default {
  name: "ElInputVeeValidate",
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props,context) {
    const { value, errorMessage } = useField(props.name);
    const showPassword = ref(true);
    const showPasswordConfir = ref(true);
     const setShowPassord = (dtName) => {
       console.log(dtName)
      if(props.name == 'password') {
        showPassword.value = !showPassword.value;
        context.emit("onsetShowConfir")
      } else {
        showPasswordConfir.value = !showPasswordConfir.value
         context.emit("onsetShowConfir")
      }
     }

      watch(
      () =>  props.name,
      (after, before) => {
        console.log(after);
      }
    );
     
    return {
      value,
      errorMessage,
      showPassword,
      showPasswordConfir,
      setShowPassord
    };
  },
};
