
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  export default {
    name: "error-response-modal",
    props: {
      open: {
        required: true,
        default: false,
        type: Boolean,
      },
      errorMessage:{
        required : true,
        default : true,
      },
      text: {
        default: 'gagal'
      }
    },
    setup(props, { emit }) {
      const store = useStore();
      const er_detail = computed(() => store.getters.getDetailError)
      const close = function (): void {
        emit("close");
      };
      return {
        close,
        er_detail
      };
    },
  };
  